/* eslint-disable */
import {
  Typography,
  Grid,
  RACButton,
  CardContent,
  Card,
  RACSelect,
  RACDatePicker,
  RACTableCell,
  RACRadio,CircularProgress,
  RACTable,
  RACTableRow,
  RACModalCard,
  RACCheckBox,
} from "@rentacenter/racstrap";
import { ReactComponent as RacFilter } from "../../../assets/images/filter-white.svg";
import { ReactComponent as AccordianOpen } from "../../../assets/images/up-arrow-filter.svg";
import { ReactComponent as AccordianClose } from "../../../assets/images/down-arrow-filter.svg";
import React, { useContext, useState } from "react";
import { ReactComponent as FilterClose } from "../../../assets/images/filter-close-icon.svg";
import moment from "moment";
import { globalStyles } from "../Styles/globalstyles";

import { ReactComponent as SuccessIcon } from "../../../assets/images/danger.svg";
import { PricingQueueContext } from "./PricingQueueContext";
import updatePricingQueueStatusAPI from "../../../api/user";
import { ProgressBar } from "../Styles/ProgressStyles";
import MissingPrice from "../../shared/MissingPriceHazard/MissingPrice";
import { HierarchyLevelDetails, HierarchyLevelInfo } from "../../shared/interface/PricingModels";

export default function PricingQueueFilter() {
  const {
    schedule,
    filterReq,isRejected,
    setFilterReq,setIsRejected,isApprover, 
    setSchedule,fullLoader,setFullLoader,
    queueType,
    setQueueType,
    setLoader,
    queueStatusType,setPageNumber,
    setQueueStatusType,comments, setComments,
    fetchPricingQueueDetail,setIsApproved,isapprovalPopup, setIsapprovalPopup,
    isFranchiseeUser,
    companyCode,
    approvalPopup,
    alterPopUp,
    setAlterPopUp,
    setApprovalPopup,
    indexValue,isAlterOpen,setIsAlterOpen,
    setIndexValue,
    zoneDetails,
    setSomeThingWentWrng,
    setZoneDetails,setTotalRowCount,
    tableBody,
  } = useContext(PricingQueueContext);
  const progressclasses = ProgressBar();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [popup, setPopup] = useState("");
  const classes = globalStyles();
  const openFilterpopup = () => {
    //To disable the future dates in RACdate Picker Using the us timezone
    const dt = new Date();
    const time = dt.toLocaleString("en-US", { timeZone: "America/Chicago" });
    const current = time.split(",")[0];
    console.log("current", moment(current).format("YYYY-MM-DD"));
    const maxdt = moment(current).format("YYYY-MM-DD");
    console.log("maxdt", maxdt);
    return (
      <Card
        className={`${classes.positionAbsolute} ${classes.filterPricingQueueDropDown}`}
      >
        <CardContent>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={`${classes.componentSpaceBetween} ${classes.mb2}`}
          >
            <Typography variant="h5">Advanced Filter</Typography>
            <FilterClose onClick={() => {setPopup(""); setIsFilterOpen(false)}}></FilterClose>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={2} xs={12} sm={12} md={12} lg={12}>
              <Grid xs={12} sm={12} md={6} lg={6} item>
                {/* Type */}
                <RACSelect
                  defaultValue={filterReq.type ? filterReq.type : ""}
                  options={queueType}
                  loading ={queueType?.length>1 ? false: true}
                  inputLabel="Type"
                  onChange={(e) => {
                    setFilterReq({ ...filterReq , ["type"]: e.target.value });
                  }}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6} item>
                {/* Status */}
                <RACSelect
                  defaultValue={filterReq.status ? filterReq.status : ""}
                  options={queueStatusType}
                  loading ={queueStatusType?.length>1 ? false: true}
                  inputLabel="Status"
                  onChange={(e) => {
                    setFilterReq({ ...filterReq, ["status"]: e.target.value });
                  }}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6} item>
                {/* Schedule */}
                <RACSelect
                  defaultValue={filterReq.schedule ? filterReq.schedule : ""}
                  options={schedule}
                  loading ={schedule?.length>1 ? false: true}
                  inputLabel="Schedule"
                  onChange={(e) => {
                    setFilterReq({
                      ...filterReq,
                      ["schedule"]: e.target.value,
                    });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} xs={12} sm={12} md={12} lg={12}>
              <Grid xs={12} sm={12} md={6} lg={6} item>
                {filterReq.toDate ? (
                  <RACDatePicker
                    label={"From"}
                    inputProps={{
                      
                      max: moment(filterReq.toDate).format("YYYY-MM-DD"),
                    }}
                    value={filterReq.fromDate}
                    onChange={(e: any) =>
                      setFilterReq({ ...filterReq, ["fromDate"]: e })
                    }
                  />
                ) : (
                  <RACDatePicker
                    label={"From"}
                    // inputProps={{
                    //   min: moment().format("YYYY-MM-DD"),
                    // }}
                    value={filterReq.fromDate}
                    onChange={(e: any) =>
                      setFilterReq({ ...filterReq, ["fromDate"]: e })
                    }
                  />
                )}
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6} item>
                {filterReq.fromDate ? 
                <RACDatePicker
                    label={"To"}
                    inputProps={{
                      
                      min: moment(filterReq.fromDate).format("YYYY-MM-DD"),
                    }}
                    value={filterReq.toDate}
                    onChange={(e: any) => {
                      debugger;
                      const date1 = moment(e, "YYYY-MM-DD");
                      const date2 = moment(filterReq.fromDate, "YYYY-MM-DD");
                      if (date2.isAfter(date1))
                        setFilterReq({
                          ...filterReq,
                          ["toDate"]: e,
                          ["fromDate"]: e,
                        });
                      else
                        setFilterReq({
                          ...filterReq,
                          ["toDate"]: e,
                        });
                    }}
                  />
                  :
                  <RACDatePicker
                  label={"To"}
                 
                  value={filterReq.toDate}
                  onChange={(e: any) => {
                    debugger;
                    const date1 = moment(e, "YYYY-MM-DD");
                    const date2 = moment(filterReq.fromDate, "YYYY-MM-DD");
                    if (date2.isAfter(date1))
                      setFilterReq({
                        ...filterReq,
                        ["toDate"]: e,
                        ["fromDate"]: e,
                      });
                    else
                      setFilterReq({
                        ...filterReq,
                        ["toDate"]: e,
                      });
                  }}
                />}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={` ${classes.mt4}  ${classes.componentRight}`}
          >
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.mr2}
              onClick={() => {
                setFilterReq({});
              }}
            >
              Clear
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              className={classes.mr2}
              disabled={ isFranchiseeUser && companyCode.length == 0  ||( (filterReq.fromDate && !filterReq.toDate )|| (!filterReq.fromDate && filterReq.toDate ))? true : false}
              onClick={() => {
                              arr= Object.keys(filterReq)
                              arr.forEach((item : any )=>{if(!filterReq[item]) delete filterReq[item]})
                              filterReq.companyCode = isFranchiseeUser
                                ? companyCode
                                : undefined;
                                filterReq.offset ="0"
                              fetchPricingQueueDetail(filterReq);
                              setPopup("");
                              setTotalRowCount(0);
                              setPageNumber(0);
                            }}
                          >
              Apply
            </RACButton>
          </Grid>
        </CardContent>
      </Card>
    );
  };
  const pricingHeaderArr = [
    {
      label: "Type",
      key: "pricingType",
      format:'text'
    },
    {
      label: "Weekly Rate New",
      key: "weeklyRateNew",
      format:'dollar'
    },
    {
      label: "Weekly Rate Used",
      key: "weeklyRateUsed",
      format:'dollar'
    },
    {
      label: "Term",
      key: "term",
      format:'text'
    },
    {
      label: "Add-On Rate",
      key: "weeklyRateAddOn",
      format:'dollar'
    },
    {
      label: "Cash Price Factor",
      key: "cashPriceFactor",
      format: 'text'
    },
    {
      label: "Forced Cash Price",
      key: "forcedCashPrice",
      format:'text'
    },
    {
      label: "Manual PO-Turns",
      key: "manualPoTurn",
      format: 'text'
    },
    {
      label: "SAC Days",
      key: "sacDays", 
      format:'text'
    },
    {
      label: "SAC Days Printed",
      key: "sacDaysPrinted",
      format: 'text'
    },
    {
      label: "EPO Percentage",
      key: "epoPercent",
      format:'text'
    },
    {
      label: "Start Date",
      key: "startDate",
      format:'date'
    },
    {
      label: "End Date",
      key: "endDate",
      format: 'date'
    },
  ];
  const storeZoneHeader = [
    {
      label: "Store",
      key: "storeNumber",
    },
    {
      label: "Zone",
      key: "zoneNumber",
    },
    {
      label: "Zone Name",
      key: "zoneName",
    },
    {
      label: "Company Name",
      key: "companyName",
    },
    {
      label: "Company Code",
      key: "companyCode",
    },
    {
      label: "Store Type",
      key: "storeType",
    },
    {
      label: "State",
      key: "state",
    },
    {
      label: "Start Date",
      key: "startDate",
    },
  ];
  const updatePricingQueueStatus = async (params: any) => {
    try {
      setFullLoader(true);
      setIsAlterOpen(false)
      let req = {
        pricingQueueId: indexValue.queueId.toString(),
        status: params,
        comments: comments ? comments : undefined,
        actionBy: sessionStorage.getItem("userName"),
      };
      const response = await updatePricingQueueStatusAPI(req);
      setAlterPopUp();

      if (response.status == 200) {
        setFullLoader(false);
        filterReq.companyCode=isFranchiseeUser ? companyCode : undefined;
        await fetchPricingQueueDetail(filterReq);
        setIsApproved(true)
      } else {
        setSomeThingWentWrng(true);
        setFullLoader(false);
      }
    } catch (e) {
      setFullLoader(false);
    }
  };
  let uniqueZones;
  let uniqueRmsItemNumbers;
  let departments;
  let subdepartments;
  let brackets;
  let validationMessage:string = ''
  const pricingApprovalPopup = () => {
    {
      if (approvalPopup == "pricing") {
        const hierarchyLevelDetails : HierarchyLevelDetails[] = indexValue.pricing[0].hierarchyLevelDetails;

        const uniqueValues = hierarchyLevelDetails.reduce((acc: any, item: HierarchyLevelDetails) => {
          acc.zones.add(item.zone);
          item.hierarchyLevelInfo.forEach((info: HierarchyLevelInfo) => {
            acc.rmsItemNumbers.add(info.rmsItemNumber);
            acc.departments.add(info.departmentName);
            acc.subdepartments.add(info.subdepartmentName);
            acc.brackets.add(info.bracketName);
          });
          return acc;
        }, {
          zones: new Set(),
          rmsItemNumbers: new Set(),
          departments: new Set(),
          subdepartments: new Set(),
          brackets: new Set()
        });
        uniqueZones = Array.from(uniqueValues.zones);
         uniqueRmsItemNumbers = Array.from(uniqueValues.rmsItemNumbers);
         departments = Array.from(uniqueValues.departments);
         subdepartments = Array.from(uniqueValues.subdepartments);
         brackets = Array.from(uniqueValues.brackets);

         const counts = {
          zones: Array.from(uniqueValues.zones).filter(Boolean).length,
          rmsItemNumbers: Array.from(uniqueValues.rmsItemNumbers).filter(Boolean).length,
          departments: Array.from(uniqueValues.departments).filter(Boolean).length,
          subdepartments: Array.from(uniqueValues.subdepartments).filter(Boolean).length,
          brackets: Array.from(uniqueValues.brackets).filter(Boolean).length
        };
    const pluralize = (word: string, count: number) => {
          const paddedCount = count.toString().padStart(2, '0');
          return `${paddedCount} ${word}${count > 1 ? 's' : ''}`;
        };
const parts = [
  pluralize('Zone', counts.zones),
  counts.departments > 0 && counts.subdepartments === 0 && counts.brackets === 0 && counts.rmsItemNumbers === 0
    ? pluralize('Department', counts.departments)
    : null,
  counts.subdepartments > 0 && counts.brackets === 0 && counts.rmsItemNumbers === 0
    ? [pluralize('Department', counts.departments), pluralize('Sub Department', counts.subdepartments)]
    : null,
  counts.brackets > 0 && counts.rmsItemNumbers === 0
    ? [pluralize('Department', counts.departments), pluralize('Sub Department', counts.subdepartments), pluralize('Bracket', counts.brackets)]
    : null,
  counts.rmsItemNumbers > 0
    ? [pluralize('Department', counts.departments), pluralize('Sub Department', counts.subdepartments), pluralize('Bracket', counts.brackets), pluralize('RMS #', counts.rmsItemNumbers)]
    : null
].filter(Boolean).flat();

 validationMessage = parts.join(parts.length > 2 ? ', ' : ' and ').replace(/,([^,]*)$/, ' and$1');
      }
    }
    return (
      <>
        <Grid className={classes.p4} style={{paddingTop:'0.5rem'}}>
          <Grid container spacing={2}>
            {approvalPopup == "pricing" ? (
              <>
                {pricingHeaderArr.map((item: any) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Typography
                        className={`${classes.fontBold} ${classes.p2}}`}
                        style={{
                          paddingBottom: "5%",
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                      
                        className={`${classes.fontSemiBold} ${classes.p2}}`}
                      >
                        {(item.format == 'dollar') ? indexValue.pricing[0][item.key] != null ?
                           `$${Number(indexValue.pricing[0][item.key])?.toFixed(2) 
                          }`: '-'
                          : (item.format == 'date')
                            ? moment(indexValue.pricing[0][item.key]).format(
                              "MM/DD/YYYY"
                            )
                            :  indexValue.pricing[0][item.key] 
                                ? indexValue.pricing[0][item.key]
                                : "-"}
                      </Typography>
                    </Grid>
                  );
                })}
                {/* <Grid md={12}>
                  <RACCheckBox label="Clearance" size="medium" disabled />
                </Grid> */}
                <Grid spacing={4} style={{ margin: "1%" }} md={12}>
                  <Typography className={classes.fontBold}>
                  Do you want to initiate Clearance?
                  </Typography>
                  <Grid 
                    style={{
                      margin: "2%",
                    }}
                    className={classes.CustomRACRadio}
                  >
                    <RACRadio
                      value={"Yes"}
                      checked={
                        indexValue.pricing[0]?.clearance?.toLowerCase() == "1" ? true : false
                      }
                      label="yes"
                      name="yes"
                      disabled={true}
                    ></RACRadio>
                    <RACRadio
                      value={"no"}
                      checked={
                        indexValue.pricing[0]?.clearance?.toLowerCase() == "0" ? true : false
                      }
                      label="no"
                      name="no"
                      disabled={true}
                    ></RACRadio>
                  </Grid>
                </Grid>
                <Grid spacing={4} style={{ margin: "1%" }} md={12}>
                  <Typography className={classes.fontBold}>
                    {" "}
                    How do you want to update the pricing?
                  </Typography>
                  <Grid 
                    style={{
                      margin: "2%",
                    }}
                  >
                    <RACRadio
                      value={"IMMEDIATE"}
                      checked={
                        indexValue.schedule == "IMMEDIATE" ? true : false
                      }
                      label="Immediately"
                      name="updateType"
                      disabled={true}
                    ></RACRadio>
                    <RACRadio
                      value={"OVERNIGHT"}
                      checked={
                        indexValue.schedule == "OVERNIGHT" ? true : false
                      }
                      label="Overnight"
                      name="updateType"
                      disabled={true}
                    ></RACRadio>
                  </Grid>
                  <Typography>
                    <span className={classes.fontBold}>Note: </span>
                    <span
                    className={classes.fontSemiBold}
                      style={{
                        fontWeight: "lighter",
                        color: "gray",
                      }}
                    >
                      The selected criteria matches with&nbsp;
                      <a
                      className={classes.fontSemiBold}
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          textDecoration:'underline'
                        }}
                        onClick={() => {
                          setZoneDetails(true);
                          setIsapprovalPopup(false);
                        }}
                      >
                        {validationMessage}
                      </a>
                    </span>
                  </Typography>
                </Grid>
              </>
            ) : approvalPopup.toLowerCase() == "storezone" ? (
              <>
                {storeZoneHeader.map((item: any) => {
                  console.log("Hello storeZOne", storeZoneHeader);

                  return (
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Typography
                        className={`${classes.fontBold}${classes.p2} ${classes.fontBold}}`}
                        style={{ paddingBottom: "5%" }}
                      >
                        {item.label}
                      </Typography>
                      {indexValue?.storeZone &&
                        indexValue?.storeZone[0][item.key] ? (
                        <Typography
                          className={classes.fontSemiBold}
                          title={
                            indexValue?.storeZone
                              ? indexValue?.storeZone[0][item.key].length > 25
                                ? indexValue?.storeZone[0][item.key].slice(
                                  0,
                                  25
                                ) + "..."
                                : ""
                              : ""
                          }
                        >
                          {item.key == pricingHeaderArr[8].key
                            ? moment(indexValue?.storeZone[0][item.key]).format(
                              "MM-DD-YYYY"
                            )
                            : indexValue?.storeZone[0][item.key]
                              ? indexValue?.storeZone[0][item.key].length > 25
                                ? indexValue?.storeZone[0][item.key].slice(
                                  0,
                                  25
                                ) + "..."
                                : indexValue?.storeZone[0][item.key]
                              : "-"}{" "}
                        </Typography>
                      ) : null}
                    </Grid>
                  );
                })}
                <Grid spacing={4} item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "1%" }}>
                  <Typography className={classes.fontBold}>
                    {" "}
                    How do you want to update the storezone?
                  </Typography>
                  <Grid
                    style={{
                      margin: "2%",
                    }}
                  >
                    <RACRadio
                      value={"IMMEDIATE"}
                      checked={
                        indexValue.schedule == "IMMEDIATE" ? true : false
                      }
                      label="Immediately"
                      name="updateType"
                      disabled={true}
                    ></RACRadio>
                    <RACRadio
                      value={"OVERNIGHT"}
                      checked={
                        indexValue.schedule == "OVERNIGHT" ? true : false
                      }
                      label="Overnight"
                      name="updateType"
                      disabled={true}
                    ></RACRadio>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>

          <Typography variant="h4" style={{ marginTop: "2%" }}>
            Comments
          </Typography>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <textarea
              rows={6}
              style={{ resize: "none", width: "100%", border:'1px solid lightgray !important' }}
              disabled={
                indexValue?.status?.toUpperCase() == "QUEUED" ||
                  indexValue?.status?.toUpperCase() == "WAITING"
                  ? false
                  : true
              }
              value={comments}
              onChange={(e: any) => {
                setComments(e.target.value);
              }}
            ></textarea>
          </Grid>
          <Grid
            style={{
              float: "right",
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              marginTop: "2%",
              height: "45px",
            }}
            md={12}
          >
            {(indexValue?.status?.toUpperCase() == "WAITING" ||
              indexValue?.status?.toUpperCase() == "PENDING") /* && isApprover */ ? (
              <>
                <RACButton
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ float: "right", backgroundColor: "#2CD19D", opacity:`${isApprover ?'100%':'60%'}`}}
                  disabled={isApprover ? false : true}
                  onClick={() => {

                    setAlterPopUp({
                      text: `approve ${approvalPopup.toLowerCase() == "pricing"
                          ? "Pricing"
                          : approvalPopup.toLowerCase() == "storezone"
                            ? "StoreZone"
                            :  approvalPopup.toLowerCase() == "secondary"
                            ? "Secondary criteria" :""
                        }`,
                      value: "A",
                    });
                    setIsRejected(false)
                    setIsapprovalPopup(false);
                    setIsAlterOpen(true);
                    // setApprovalPopup("");

                    // if(addPricingReq.pricingType!= 'TEMPORARY' && addPricingReq.endDate =='');
                    //need to add start date & end date validation
                  }}
                >
                  Approve
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ backgroundColor: "#E85C5C", marginRight: "2%" , opacity:`${comments!= undefined && comments!= null && comments.trim() && isApprover ?'100%':'60%'}` }}
                  disabled={comments && isApprover  ? false : true}
                  onClick={() => {
                    setAlterPopUp({
                      text: `reject ${approvalPopup.toLowerCase() == "pricing"
                          ? "Pricing"
                          : approvalPopup.toLowerCase() == "storezone"
                            ? "StoreZone"
                            : approvalPopup.toLowerCase() == "secondary"
                            ? "Secondary criteria" :""
                        }`,
                      value: "R",
                    });
                    setIsapprovalPopup(false);
                    setIsAlterOpen(true);
                    setIsRejected(true);
                    // setApprovalPopup("");

                    // if(addPricingReq.pricingType!= 'TEMPORARY' && addPricingReq.endDate =='');
                    //need to add start date & end date validation
                  }}
                >
                  Reject
                </RACButton>
              </>
            ) : indexValue?.status?.toUpperCase() == "QUEUED" &&
              indexValue.schedule.toUpperCase() == "OVERNIGHT" /*  && isApprover  */ ? (
              <RACButton
                variant="contained"
                color="primary"
                size="small"
                style={{ backgroundColor: "#E85C5C", marginRight: "2%" }}
                disabled={isApprover ? false : true}
                onClick={() => {
                  setAlterPopUp({
                    text: `cancel ${
                      approvalPopup.toLowerCase() == "pricing"
                        ? "pricing"
                        : approvalPopup.toLowerCase() == "storezone"
                          ? "storeZone"
                          : ""
                      }`,
                    value: "C",
                  });
                  setIsapprovalPopup(false);
                  setIsRejected('C');
                  setIsAlterOpen(true);
                  // setApprovalPopup("");

                  // if(addPricingReq.pricingType!= 'TEMPORARY' && addPricingReq.endDate =='');
                  //need to add start date & end date validation
                }}
              >
                Cancel Approval
              </RACButton>
            ) : (
              <></>
            )}
            <RACButton
              variant="outlined"
              color="primary"
              size="small"
              style={{ float: "right", marginRight: "2%" }}
              onClick={() => {
                setIsapprovalPopup(false);
                setComments("");
              }}
            >
              Cancel
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return typeof str === 'string' && str.length > max
      ? str.substring(0, max) + add
      : str;
  };
  let arr: any = [];
  const renderRmsDetailsTableBody = () => {
    arr = indexValue.pricing[0].hierarchyLevelDetails;

    return (
      <>
        {arr?.map((items: any, index: any) => (
          <React.Fragment key={index}>
            {items.hierarchyLevelInfo.map((item: any) => (
              <RACTableRow key={item.departmentName} style={{ background: "white" }}>
                <RACTableCell
                className={classes.fontSemiBold}
                  style={{ color: "gray" }}
                >
                  {items.zone}
                </RACTableCell>
                <RACTableCell
                className={classes.fontSemiBold}
                  style={{ color: "gray" }}
                >
                  {item.departmentName ? truncString(item.departmentName,25,'...' ): '-' }
                </RACTableCell>
                <RACTableCell
                className={classes.fontSemiBold}
                  style={{  color: "gray" }}
                >
                  {item.subdepartmentName ?truncString(item.subdepartmentName,25,'...') :'-'}
                </RACTableCell>
                <RACTableCell
                className={classes.fontSemiBold}
                  style={{ color: "gray" }}
                >
                  {item.bracketName ? truncString(item.bracketName,25,'...') :'-'}
                  </RACTableCell>
                <RACTableCell
                className={classes.fontSemiBold}
                  style={{ color: "gray" }}
                >
                  {item.rmsItemNumber ? item.rmsItemNumber :'-'}
                  </RACTableCell>
              </RACTableRow>
            ))}
          </React.Fragment>
        ))}
      </>
    );
  };
  const renderRmsDetailsTableHeader = () => {
    return (
      <>
        <RACTableCell>Zone</RACTableCell>
        <RACTableCell>Department</RACTableCell>
        <RACTableCell>Sub Department</RACTableCell>
        <RACTableCell>Bracket</RACTableCell>
        <RACTableCell>RMS Item #</RACTableCell>
      </>
    );
  };
  const rmsSelectedPopup = () => {
    return (
      <Grid className={classes.zoneDetailHeight}>
        <RACTable
          renderTableContent={renderRmsDetailsTableBody}
          renderTableHead={renderRmsDetailsTableHeader}
          style={{
            width: "100%",
            background: "white",
            whiteSpace: "nowrap",
          }}
        />
      </Grid>
    );
  };
  const alterPopupFn = () => {
    return (
      <>
        <Grid>
          <Grid style={{ textAlign: "center", marginBottom: "2%" }}>
            <SuccessIcon />
          </Grid>
          <Grid>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              {`Do you want to ${alterPopUp?.text}?`}
            </Typography>
          </Grid>
          <Grid
            style={{ display: "flex", justifyContent: "center", margin: "5%" }}
          >
            <RACButton
              style={{ marginRight: "10%" }}
              variant="outlined"
              onClick={() => {
                setIsAlterOpen(false);
                

              }}
            >
              No
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => {
                updatePricingQueueStatus(alterPopUp.value);
              }}
            >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <>
      <Grid container alignItems="center" style={{ padding: "0.5% 0%" }}>
        <Grid item xs={6} sm={8} md={9} lg={10} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4">Pricing Queue</Typography>
        </Grid>

        <Grid item xs={6} sm={4} md={3} lg={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <MissingPrice />
          <RACButton
            variant="contained"
            color="primary"
            size="small"
            style={{ cursor: "pointer", marginLeft: '8px' }}
            onClick={() => {
              setPopup(popup ? "" : "filter");
              setIsFilterOpen(!isFilterOpen);
            }}
          >
            <RacFilter></RacFilter>
            <span style={{ margin: "0px 8px" }}>Filter</span>
            {/* Need to add filter icon !important */}
            {/* {isFilterOpen ? <AccordianClose /> : <AccordianOpen />} */}
          </RACButton>
        </Grid>
      </Grid>
      {popup == "filter" ? openFilterpopup() : null}
      <RACModalCard
        isOpen={isapprovalPopup }
        title={
          approvalPopup == "pricing" ? "Pricing Approval" : "StoreZone Approval"
        }
        maxWidth="md"
        closeIcon={true}
        onClose={() => {
           setIsapprovalPopup(false);
          }}
        borderRadius={10}
        titleVariant="h4"
        >
        {pricingApprovalPopup()}
      </RACModalCard>
      <Grid>
        <RACModalCard
          isOpen={zoneDetails}
          title="Zone Details"
          maxWidth="md"
          closeIcon={true}
          onClose={() => {
            setApprovalPopup('pricing')
            setIsAlterOpen(false)
            setZoneDetails(false)
            setIsapprovalPopup(true);
          }}
          borderRadius={10}
          titleVariant="h4"
          TitleClassName={classes.customTitle}
          // className={classes.modelCardZoneDetail}
        >
          {rmsSelectedPopup()}
        </RACModalCard>
      </Grid>
      <RACModalCard
        isOpen={isAlterOpen ? true : false}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => {
          setIsAlterOpen(false)
          }}
        children={alterPopupFn()}
        title=""
      />
      {
        fullLoader? <>
        <Grid className={progressclasses.masterLoader}>
          <Grid className={progressclasses.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
        </>: null
      }
    </>
  );
}
