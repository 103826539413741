/* eslint-disable */
import {
  GetMenuAndStoredbasedOnProfileAPI,
  fetchDropDownAPI,
  getUserID,
  getZoneDD,
} from "../../api/user";
import { SECONDARY_CRITERIA, SECONDARY_CRITERIA_EQUATION } from "../../constants/constants";
import { responseGetProfileBasedOnMenu, PricingDropdownDetailResponse } from "./interface/getProfileBasedOnMenuInterface";
export const getEmployeeInfoAndReferenceData = async (containerProps: any, type: any = [], onlyMenu?: boolean) => {
  let userName;
  let employeeId;
  let menuConfig;
  let email;
  let placeHolderDDdata: any = {};
  console.log(containerProps, "the container props in the getEmployeeInfoAndReferenceData");

  if (containerProps !== undefined) {
    sessionStorage.setItem('currentRole', containerProps.GetRole());
    employeeId = await containerProps.GetEmployeeId();

    userName = containerProps.GetName();
    menuConfig = containerProps?.GetMenuConfig();
    email = containerProps.GetEmailId();
    console.log("employeeId from containerProps::", employeeId);
    console.log("menuConfig from containerProps::", menuConfig);
  } else {
    const userDetails = await getUserID();
    console.log("userDetails console", userDetails);
    employeeId = userDetails?.data.employeeId;
    email = userDetails?.data?.email;
    userName = `${userDetails?.data.firstName} ${userDetails?.data.lastName}`;
    console.log("employeeId from GetCurrentUser call::", employeeId);
    console.log("username for the user", userName)
  }

  if (onlyMenu) {
    if (containerProps == undefined || !menuConfig) {
      let req = {
        coworkerId: employeeId,
        menuRequired: true
      };
      const response = await GetMenuAndStoredbasedOnProfileAPI(req);
      console.log('onlyMenu api response', response)
      return response?.data?.menuConfig || [];
    }
    return menuConfig
  }

  let req = {
    coworkerId: employeeId,
    menuRequired: containerProps !== undefined ? false : true,
    companyCodeRequired: true,
  };


  const response = await Promise.all([
    GetMenuAndStoredbasedOnProfileAPI(req),
    getZoneDD(),
    fetchDropDownAPI(type)
  ]);


  console.log("Response user data", response[0], response[1], response[2], '\nmenu\n', response[0].data.menuConfig);

  const responseData: responseGetProfileBasedOnMenu = {
    status: 0,
    role: "",
    employeeId: employeeId,
    emailId: email,
    userName: userName,
    companyCode: response[0].data.companyCode,
    isFranchiseeUser: response[0].data.franchiseeUser,
    zone: [],
    dropdownData: placeHolderDDdata,
    menuConfigArray: containerProps !== undefined ? menuConfig : response[0].data.menuConfig
  };

  console.log("responseData", responseData);
  if (response[0].status == 200 && response[1].status == 200) {
    console.log("response[1].data.result.companyCode.length", response[1].data.result.length)
    let zoneArray = []
    for (let i = 0; i < response[1].data.result.length; i++) {
      if (responseData.isFranchiseeUser && responseData.companyCode.includes(response[1].data.result[i].companyCode)) {
        zoneArray.push({
          label: response[1].data.result[i].zone,
          value: response[1].data.result[i].zone,
        })
      }
      else if (!responseData.isFranchiseeUser) { //for pricing team
        zoneArray.push({
          label: response[1].data.result[i].zone,
          value: response[1].data.result[i].zone,
        })
      }
    }
    sessionStorage.setItem("userName", userName);

    responseData.zone = zoneArray
    console.log("responseData.zone", responseData.zone)
    responseData.status = 200;
    responseData.role = response[0].data.coworkerProfile.role;
    responseData.dropdownData = response[2].data;
    responseData.userName = userName;
  }
  else {
    return responseData;
  }

  return responseData;
};

export function validateApprover(menuConfig: any, moduleName: any = 'Pricing Queue') {

  return menuConfig?.some((item: any) => (item.appComponentFunctionName == moduleName && item.edit == 1))
}
export function accessFeature(menuConfig: any, moduleName: any = SECONDARY_CRITERIA, privilege: string = 'view') {
  console.log('The meu config',menuConfig[0][privilege])
  return menuConfig?.some((item: any) => (item.appComponentFunctionName == moduleName && item[privilege] == 1))
}
export function findMenu(menuconfig: any, moduleName: string = SECONDARY_CRITERIA_EQUATION) {
  return menuconfig?.some((item: any) => (item.appComponentFunctionName === moduleName))
}
