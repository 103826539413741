/* eslint-disable */
import {
    Typography,
    Grid,
    Card,
    RACTable,
    RACTableRow,
    RACTableCell,
    List,
    ListItem,
    RACRadio,
  } from "@rentacenter/racstrap";
  import React, { useContext } from "react";
  import moment from "moment";
  import { globalStyles } from "../Styles/globalstyles";
  import { ReactComponent as SortUpIcon } from "../../../assets/images/sort-up-icon.svg";
import { ReactComponent as SortDownIcon } from "../../../assets/images/sort-down-icon.svg";
import { PricingQueueContext } from "./PricingQueueContext";

export default function CrtieriaApproval (){
      const {
        indexValue,
        setIndexValue,
        setHideMainPage,
        criteriaHeader, setCriteriaHeader,
        criteriaRange, setcriteriaRange,
        comments,
        setComments,
      } = useContext(PricingQueueContext);
      const daysList:string[] =['EpoPercent','WeeklyTerm', 'SacDays'];
        const Classes = globalStyles();
        const sortArray = (
            columnId: string,
            index: any,
            datacolumn: any,
            dataValues: any,
            compareBy: string = ""
          ) => {
            try {
              debugger;
              let sortedData;
              if (compareBy == "date") {
                sortedData =
                  datacolumn[index].sortDirection == "DESC"
                    ? dataValues.sort((a: any, b: any) => {
                        let date1 = moment(b[columnId]);
                        let date2 = moment(a[columnId]);
                        return date2.isBefore(date1) ? -1 : 1;
                      })
                    : dataValues.sort((a: any, b: any) => {
                        let date1 = moment(b[columnId]);
                        let date2 = moment(a[columnId]);
                        return date1.isBefore(date2) ? -1 : 1;
                      });
              } else if (compareBy == "number") {
                sortedData =
                  datacolumn[index].sortDirection == "DESC"
                    ? dataValues.sort((a: any, b: any) => b[columnId] - a[columnId])
                    : dataValues.sort((a: any, b: any) => a[columnId] - b[columnId]);
              } else {
                sortedData =
                  datacolumn[index].sortDirection == "DESC"
                    ? dataValues.sort((a: any, b: any) =>
                        b[columnId] < a[columnId] ? -1 : 1
                      )
                    : dataValues.sort((a: any, b: any) =>
                        a[columnId] < b[columnId] ? -1 : 1
                      );
              }
              const reorderColumns = datacolumn;
              reorderColumns[index].sortDirection =
                reorderColumns[index].sortDirection == "ASC" ? "DESC" : "ASC";
              reorderColumns.map((value: any, index1: any) => {
                reorderColumns[index1].IsSorted = index == index1 ? true : true;
              });
              console.log("sortedData", sortedData);
              return [[...sortedData], [...reorderColumns]];
            } catch (error) {
              console.log(error);
            }
          };
          const valueMapping :any= {
            WeeklyRateNew: "Weekly Rate New",
            WeeklyRateUsed: "Weekly Rate Used",
            WeeklyTerm: "Term",
            WeeklyAddOnRate: "AddOn Rate",
            CashPriceMultiplier: "Cash Price",
            SacDays: "SAC Days",
            EpoPercent: "EPO Percentage"
          }
          const RangeHead = () => (
            <>
              {criteriaHeader.map((value: any, index: any) => {
                return (
                  <>
                    <RACTableCell>
                      {value.isSortable ? (
                        <a
                          style={{
                            color: "black",
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                          id={value.columnId}
                          onClick={() => {
                            let rep: any = sortArray(
                              value.key,
                              index,
                              criteriaHeader,
                              criteriaRange,
                              "number"
                            );
                            console.log(rep, "the sort array in the form");
        
                            // setGridData(rep[0]);  need to set the data into formData.range()
                            setCriteriaHeader(rep[1]);
                            // let req = indexValue.autoTerm;
                            // req.range = rep[0];
                            setcriteriaRange(rep[0] );
                          }}
                        >
                          {value.heading + "  "}
                          {value.isSorted === true ? (
                            value.sortDirection == "DESC" ? (
                              <SortUpIcon
                                style={{ cursor: "pointer" }}
                                className={Classes.ml0}
                              />
                            ) : (
                              <SortDownIcon
                                style={{ cursor: "pointer" }}
                                className={Classes.ml0}
                              />
                            )
                          ) : null}
                        </a>
                      ) : (
                        <a
                          style={{
                            color: "black",
                            textDecoration: "none",
                          }}
                          id={value.columnId}
                        >
                          {value.heading + "  "}
                        </a>
                      )}
                    </RACTableCell>
                  </>
                );
              })}
            </>
          );
          const RangeContent = () =>
            criteriaRange.map((item: any) => (
              <>
                <RACTableRow style={{ backgroundColor: "transparent" }}>
                  <RACTableCell>
                    <Typography
                      variant="caption"
                      className={`${Classes.fontSemiBold} ${Classes.fs7} ${Classes.textGrey}`}
                    >
                      {item?.startRange}
                    </Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography
                      variant="caption"
                      className={`${Classes.fontSemiBold} ${Classes.fs7} ${Classes.textGrey}`}
                    >
                      {item?.endRange}
                    </Typography>
                  </RACTableCell>
                  <RACTableCell>
                    <Typography
                      variant="caption"
                      className={`${Classes.fontSemiBold} ${Classes.fs7} ${Classes.textGrey}`}
                    >
                      {(indexValue?.criteriaDetails?.effectType?.toUpperCase() =='NUMBER' && !daysList.includes(indexValue?.criteriaDetails?.pricingParameterKey))? '$' : ''}{Number(item?.discountValue)?.toFixed(3)}{(indexValue?.criteriaDetails?.effectType?.toUpperCase() !='NUMBER')? '%' : ''}
                    </Typography>
                  </RACTableCell>
                </RACTableRow>
              </>
            ));
            function titleCase(str : any) {
              return str?.toLowerCase().replace(/\b\w/g, (char:any) => char.toUpperCase());
          }
          
          
    return (<>
      <Grid style={{ width: "100%", paddingTop: "0.75rem" }}>
        {/* braedcrumbs starts  */}
        <List className={`${Classes.breadcrumb} ${Classes.py1}`}>
          <ListItem
            style={{ paddingLeft: "0" }}
            className={`${Classes.breadcrumbItemActive} `}
          >
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIndexValue("");
                setHideMainPage(false);
              }}
            >
              Pricing Queue
            </a>
          </ListItem>
          <ListItem
            className={`${Classes.breadcrumbItemActive1}  ${Classes.breadcrumbItem} ${Classes.breadcrumbArrow} `}
          >
            <a>Secondary Criteria Approval</a>
          </ListItem>
        </List>
        {/* braedcrumbs ends  */}

        <Grid container spacing={2}>
          {/* Update criteriaDetails starts  */}
          <Grid item xs={12} md={12} lg={5}>
            <Typography variant="h4">Secondary Criteria Approval</Typography>
            <Card className={`${Classes.p3} `} style={{ height: "74vh" }}>
              <Grid container spacing={2} style={{overflowY : 'auto',height: "72vh"  }}>
                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold} ${Classes.mt3} `}>
                    Zone
                  </Typography>
                  <Typography title={indexValue?.criteriaDetails.zoneNumber?.join(', ').length > 17?  (indexValue?.criteriaDetails.zoneNumber.join(', ')):''}>{indexValue?.criteriaDetails.zoneNumber ?(indexValue?.criteriaDetails.zoneNumber?.join(', ')).length > 17 ? (indexValue?.criteriaDetails.zoneNumber.join(', ')).slice(0,17) + '...' : (indexValue?.criteriaDetails.zoneNumber.join(', ')) : '-'}</Typography>
                  {/* <Typography>{indexValue?.zoneNumber}</Typography> */}
                </Grid>
                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold} ${Classes.mt3} `}>
                    Department
                  </Typography>
                  <Typography
                    className={`${Classes.mt3} `}
                    title={
                      indexValue.criteriaDetails.departmentDesc
                        ? indexValue.criteriaDetails.departmentDesc.length > 25
                          ? indexValue.criteriaDetails.departmentDesc
                          : ""
                        : ""
                    }
                  >
                    {indexValue.criteriaDetails.departmentDesc
                      ? indexValue.criteriaDetails.departmentDesc.length > 25
                        ? indexValue.criteriaDetails.departmentDesc.slice(0, 25) +
                          "..."
                        : indexValue.criteriaDetails.departmentDesc
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold} ${Classes.mt3} `}>
                    SubDepartment
                  </Typography>
                  <Typography
                    className={`${Classes.mt3} `}
                    title={
                      indexValue.criteriaDetails.subdepartmentDesc
                        ? indexValue.criteriaDetails.subdepartmentDesc.length > 25
                          ? indexValue.criteriaDetails.subdepartmentDesc
                          : ""
                        : ""
                    }
                  >
                    {indexValue.criteriaDetails.subdepartmentDesc
                      ? indexValue.criteriaDetails.subdepartmentDesc.length > 25
                        ? indexValue.criteriaDetails.subdepartmentDesc.slice(0, 25) +
                          "..."
                        : indexValue.criteriaDetails.subdepartmentDesc
                      : "-"}
                  </Typography>
                </Grid>

                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold}  `}>
                    Bracket
                  </Typography>
                  <Typography
                    className={`${Classes.mt3} `}
                    title={
                      indexValue.criteriaDetails.bracketName
                        ? indexValue.criteriaDetails.bracketName.length > 25
                          ? indexValue.criteriaDetails.bracketName
                          : ""
                        : ""
                    }
                  >
                    {indexValue.criteriaDetails.bracketName
                      ? indexValue.criteriaDetails.bracketName.length > 25
                        ? indexValue.criteriaDetails.bracketName.slice(0, 25) + "..."
                        : indexValue.criteriaDetails.bracketName
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold} `}>
                    Brand
                  </Typography>
                  <Typography
                    className={`${Classes.mt3} `}
                    title={
                      indexValue.criteriaDetails.brandName
                        ? indexValue.criteriaDetails.brandName.length > 25
                          ? indexValue.criteriaDetails.brandName
                          : ""
                        : ""
                    }
                  >
                    {indexValue.criteriaDetails.brandName
                      ? indexValue.criteriaDetails.brandName.length > 25
                        ? indexValue.criteriaDetails.brandName.slice(0, 25) + "..."
                        : indexValue.criteriaDetails.brandName
                      : "-"}
                  </Typography>
                </Grid>

                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold} `}>
                    RMS #
                  </Typography>
                  <Typography className={`${Classes.mt3} `}>
                    {indexValue?.criteriaDetails.rmsItemNumber ?? '-'}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold} `}>
                    Rule Type
                  </Typography>
                  <Typography
                    className={`${Classes.mt3} `}
                    title={
                      indexValue.criteriaDetails.ruleType
                        ? indexValue.criteriaDetails.ruleType.length > 25
                          ? indexValue.criteriaDetails.ruleType
                          : ""
                        : ""
                    }
                  >
                    {indexValue.criteriaDetails.ruleType
                      ? indexValue.criteriaDetails.ruleType.length > 25
                        ? indexValue.criteriaDetails.ruleType.slice(0, 25) +
                          "..."
                        : indexValue.criteriaDetails.ruleType
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold} `}>
                    Rule Application Order
                  </Typography>
                  <Typography className={`${Classes.mt3} `}>
                    {indexValue.criteriaDetails.applicationOrder
                    }
                  </Typography>
                </Grid>
                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold} `}>
                    Secondary Criteria Type
                  </Typography>
                  <Typography className={`${Classes.mt3} `}>
                    {indexValue.criteriaDetails.secondaryCriteriaType ? indexValue.criteriaDetails.secondaryCriteriaType  : '-'
                    }
                  </Typography>
                </Grid>
                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold} `}>
                    Price Parameter
                  </Typography>
                  <Typography className={`${Classes.mt3} `}>
                    {indexValue.criteriaDetails.pricingParameterKey ? valueMapping[indexValue.criteriaDetails.pricingParameterKey]  : '-'
                    }
                  </Typography>
                </Grid>
                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold} `}>
                    Criteria Effect Type
                  </Typography>
                  <Typography className={`${Classes.mt3} `}>
                    {titleCase(indexValue.criteriaDetails.effectType)
                    }
                  </Typography>
                </Grid>
                <Grid item xs={4} className={`${Classes.mt1} `}>
                  <Typography className={`${Classes.fontBold} `}>
                    Start Date
                  </Typography>
                  <Typography className={`${Classes.mt3} `}>
                    {moment(indexValue?.criteriaDetails.startDate).format(
                      "MM/DD/YYYY"
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={4} className={`${Classes.mt1}  `}>
                  <Typography className={`${Classes.fontBold} `}>
                    End Date
                  </Typography>
                  <Typography className={`${Classes.mt3} `}>
                    {moment(indexValue?.criteriaDetails.endDate).format("MM/DD/YYYY")}
                  </Typography>
                </Grid>
                {/*  */}
                <Grid md={12}>
                    <Typography className={Classes.fontBold}>
                                        {" "}
                                        How do you want to update the Secondary Criteria?
                                      </Typography>
                                      <Grid 
                                        style={{
                                          margin: "2%",
                                        }}
                                      >
                                        <RACRadio
                                          value={"IMMEDIATE"}
                                          checked={
                                            indexValue.schedule == "IMMEDIATE" ? true : false
                                          }
                                          label="Immediately"
                                          name="updateType"
                                          disabled={true}
                                        ></RACRadio>
                                        <RACRadio
                                          value={"OVERNIGHT"}
                                          checked={
                                            indexValue.schedule == "OVERNIGHT" ? true : false
                                          }
                                          label="Overnight"
                                          name="updateType"
                                          disabled={true}
                                        ></RACRadio>
                                      </Grid>
                </Grid>
                <Grid md={12}>
                  <Typography variant="h4" style={{ marginTop: "2%" }}>
                    Comments
                  </Typography>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <textarea
                      rows={6}
                      style={{
                        resize: "none",
                        width: "100%",
                        border: "1px solid lightgray !important",
                      }}
                      disabled={
                        indexValue?.status?.toUpperCase() == "QUEUED" ||
                        indexValue?.status?.toUpperCase() == "WAITING"
                          ? false
                          : true
                      }
                      value={comments}
                      onChange={(e: any) => {
                        setComments(e.target.value);
                      }}
                    ></textarea>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          {/* Update criteriaDetails ends  */}

          {/* Range starts  */}
          <Grid item xs={12} md={12} lg={7}>
            <Typography variant="h4">  {(indexValue.criteriaDetails.ruleType)}</Typography>
            <Card
              className={`${Classes.p3}`}
              style={{ overflowY: "auto", height: "74vh" }}
            >
              <Grid className={Classes.fixTableHeadAgrTrsRec} container >
                {
                    (indexValue.criteriaDetails?.ruleType).toLowerCase() == 'equation' ?<>
                     <Grid item xs={4} className={`${Classes.mt1}  `}>
                <Typography className={`${Classes.fontBold} `}>
                  Equation Type
                </Typography>
                <Typography className={`${Classes.mt3} `}>
                  {indexValue?.criteriaDetails.equation.equationType}
                </Typography>
              </Grid>
              {
                indexValue?.criteriaDetails.equation.parameters.map((item : any)=>(
                <Grid item xs={4} className={`${Classes.mt1}  `}>
                {item?.equationParamKey && item?.effectValue ?<Typography className={`${Classes.fontBold} `}>
                 {item.equationParamKey.toUpperCase()}
                </Typography> : null}
                {item?.equationParamKey && item?.effectValue ?<Typography className={`${Classes.mt3} `}>
                {(indexValue?.criteriaDetails?.effectType?.toUpperCase() =='NUMBER' && !daysList.includes(indexValue?.criteriaDetails?.pricingParameterKey))? '$' : ''}{Number(item?.effectValue)?.toFixed(3)}{(indexValue?.criteriaDetails?.effectType?.toUpperCase() !='NUMBER')? '%' : ''}
                </Typography>: null}
              </Grid>
                ))
              }
              </> :
                    (indexValue.criteriaDetails?.ruleType).toLowerCase() == 'category' ?<>
                    <Grid item xs={4} className={`${Classes.mt1}  `}>
                <Typography className={`${Classes.fontBold} `}>
                  Category Type
                </Typography>
                <Typography className={`${Classes.mt3} `}>
                  {indexValue?.criteriaDetails.category.categoryType}
                </Typography>
              </Grid>
              <Grid item xs={4} className={`${Classes.mt1}  `}>
                <Typography className={`${Classes.fontBold} `}>
                  Category Subtype
                </Typography>
                <Typography className={`${Classes.mt3} `}>
                  {indexValue?.criteriaDetails.category.categorySubType}
                </Typography>
              </Grid>
              <Grid item xs={4} className={`${Classes.mt1}  `}>
                <Typography className={`${Classes.fontBold} `}>
                  Effect Value
                </Typography>
                <Typography className={`${Classes.mt3} `}>
                {(indexValue?.criteriaDetails?.effectType?.toUpperCase() =='NUMBER' && !daysList.includes(indexValue?.criteriaDetails?.pricingParameterKey))? '$' : ''}{Number(indexValue?.criteriaDetails.category.effectValue)?.toFixed(3)}{(indexValue?.criteriaDetails?.effectType?.toUpperCase() !='NUMBER')? '%' : ''}
                </Typography>
              </Grid>
                    </> :
                    <RACTable
                renderTableHead={RangeHead}
                renderTableContent={RangeContent}
              /> 

                }
              {/* */}
              </Grid>
            </Card>
          </Grid>
          {/* Range starts  */}
        </Grid>
      </Grid>
    </>)
} 