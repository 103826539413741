/* eslint-disable */

import React, { useContext, useState, useEffect } from 'react';
import SecondaryCriteriaFilter from './SecondaryCriteriaFilter';
import SecondaryCriteriaGrid from './SecondaryCriteriaGrid';
import { SecondaryCriteriaStyle } from '../Styles/SecondaryCriteria';
import { Grid, RACButton, Card, CardContent, RACModalCard, Typography, CircularProgress } from '@rentacenter/racstrap';
import SecondaryCriteriaForm from './SecondaryCriteriaForm';
import { CriteriaContext } from './CriteriaContext';
import { ReactComponent as WarningIcon } from "../../../assets/images/no-records-found.svg";
import { ReactComponent as Danger } from "../../../assets/images/danger.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/images/success-icon.svg";
import updatePricingQueueStatusAPI, { addPricingQueueAPI } from '../../../api/user';
import TwoFactorAuthentication from "../../shared/TwoStepAuthentication";
import moment from 'moment';
import { errorMessage } from '../../../constants/constants';
export default function SecondaryCriteria() {

  const {
    showComp, setShowComp, criteriaDetailsForm, selectQueueType, handleCancelClick, handlePopup, setHandlePopup, editCriteria, handleDeleteRow, userInfo, setLoader, loader, setBackupRecords, setCriteriaDetailsForm, criteriaDetailsObject, editRecordsArray,
    setZoneSelectedForm, setSelectQueueType, setZoneSelected, setSearchData, searchData
  } = useContext(CriteriaContext);
  const { equationAccessError } = errorMessage
  const [authOpen, setAuthOpen] = useState(false);
  const [approvedBy, setApprovedBy] = useState('');
  const [isSLACompleted, setIsSLACompleted] = useState(false);
  const [isSLACancel, setIsSLACancel] = useState(false);
  const [pricingQueueId, setPricingQueueId] = useState('');
  const [isApproved, setIsApproved] = useState(false)
  useEffect(() => {
    if (isSLACancel == false && isSLACompleted == true) {
      setHandlePopup({ ...handlePopup, isOpen: false })
      setAuthOpen(false);

      updatePricingQueueStatus('A'); //approved popup
    }
  }, [isSLACancel, isSLACompleted]);


  const classes = SecondaryCriteriaStyle();


  const updatePricingQueueStatus = async (params: any) => {
    try {
      setLoader({ ...loader, mainPage: true });
      let req = {
        pricingQueueId: pricingQueueId?.toString(),
        status: params,
        actionBy: approvedBy,
      };
      const response = await updatePricingQueueStatusAPI(req);
      if (response.status == 200) {
        setLoader({ ...loader, mainPage: false });
        setAuthOpen(false);
        setIsApproved(true);
      } else {
        setHandlePopup({ isOpen: true, Content: 'Something went wrong', index: 0, type: 'error' });
        setLoader({ ...loader, mainPage: false });
      }
    } catch (e) {
      setHandlePopup({ isOpen: true, Content: 'Something went wrong', index: 0, type: 'error' });
      setLoader({ ...loader, mainPage: false });
    }
  };
  function disableSubmitBtn() {
    const { zone, departmentNumber, subdepartmentNumber, bracketNumber, rmsItemNumber, ruleType, applicationOrder, secondaryCriteriaType, pricingParameterKey, effectType, startDate, endDate } = criteriaDetailsForm;
    const startdate = startDate && moment(startDate, "YYYY-MM-DD");
    const currentDate = moment(moment(new Date()).format("YYYY-MM-DD"), "YYYY-MM-DD");
    const enddate = endDate && moment(endDate, "YYYY-MM-DD");

    if ((zone && zone.length > 0) && (departmentNumber || subdepartmentNumber || bracketNumber || rmsItemNumber) && ruleType && applicationOrder && secondaryCriteriaType && pricingParameterKey && effectType && startDate && selectQueueType && endDate && (enddate?.isAfter(startdate) || enddate?.isSame(startdate)) && !(enddate?.isBefore(currentDate) || startdate?.isBefore(currentDate))) {
      if (ruleType === "EQUATION") {
        console.log('criteria Details equation', criteriaDetailsForm.equation?.parameters)
        return (
          criteriaDetailsForm.equation?.equationType && (criteriaDetailsForm.equation?.parameters == null || (criteriaDetailsForm.equation?.parameters && criteriaDetailsForm.equation?.parameters?.every((item: any) => item.equationParamKey && item.discountValue)))
        )
      }
      else if (ruleType == 'TABLE') {
        return (
          criteriaDetailsForm.range && editRecordsArray.length == 0 && criteriaDetailsForm.range.every((item: any) => item.startRange && item.endRange && item.discountValue)
        )

      }
      else if (ruleType == 'CATEGORY') {
        return (
          criteriaDetailsForm.category?.categoryType && criteriaDetailsForm.category?.categorySubType && criteriaDetailsForm.category?.discountValue
        )
      }
      return false
    } else {
      return false
    }
  }
  const poupUp = () => {
    return (
      <>
        <Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            {handlePopup.type == 'success' ?
              <SuccessIcon width={"10%"} height={"10%"} />
              : handlePopup.type == 'error' ?
                <WarningIcon width={"10%"} height={"10%"} />
                : <Danger width={"10%"} height={"10%"} />}
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                padding: '2% 0%'
              }}
            >
              {handlePopup.Content}
            </Typography>
            {handlePopup.type == 'success' ? <Typography variant="h5" style={{ textAlign: "center" }}>
              Do you want to continue for approval?
            </Typography> : null}
          </Grid>
          {handlePopup.type == 'success' ?
            <Grid
              style={{ display: "flex", justifyContent: "center", margin: "5%" }}
            >
              <RACButton
                style={{ marginRight: "10%" }}
                variant="outlined"
                onClick={() => setHandlePopup({ ...handlePopup, isOpen: false })}
              >
                No
              </RACButton>
              <RACButton
                variant="contained"
                color="primary"
                onClick={() => {
                  setHandlePopup({ ...handlePopup, isOpen: false })
                  setAuthOpen(true);
                }}
              >
                Yes
              </RACButton>
            </Grid>

            : handlePopup.type == 'error' ?
              <Grid
                style={{
                  justifyContent: "center",
                  display: "flex",
                  marginTop: '5%'
                }}
              >
                <RACButton
                  onClick={() => setHandlePopup({ ...handlePopup, isOpen: false })}
                  color="primary"
                  variant="contained"
                >
                  OK
                </RACButton>
              </Grid>
              :
              <Grid
                style={{ display: "flex", justifyContent: "center", margin: "3%" }}

              >

                <RACButton
                  style={{ marginRight: "6%" }}
                  onClick={() => setHandlePopup({ ...handlePopup, isOpen: false, index: undefined, type: 'error' })}
                  color="primary"
                  variant="outlined"
                >
                  No
                </RACButton>

                <RACButton
                  onClick={() => {
                    handleDeleteRow(handlePopup.index);
                  }}
                  color="primary"
                  variant="contained"
                >
                  Yes
                </RACButton>
              </Grid>
          }
        </Grid>
      </>
    );
  };
  const validateTableCriteria = () => {
    let canContinue = false;
    let arr = JSON.parse(JSON.stringify(criteriaDetailsForm.range))
    const sortedData = arr.sort(
      (a: any, b: any) => a.startRange - b.startRange
    );
    if (Number(sortedData[0].startRange) !== 0) {
      setLoader({ ...loader, dropDownLoader: false, mainPage: false });

      // errorPopup.isOpen =true;
      // errorPopup.statement ="Initial Interval missing";
      setHandlePopup({
        isOpen: true,
        type: 'error',
        Content: `Interval missing between 0 and ${Number(sortedData[0].startRange - 1)}`
      })
      return canContinue
    }

    if (Number(sortedData[sortedData.length - 1].endRange) < 9999) {
      setLoader({ ...loader, dropDownLoader: false, mainPage: false });

      // errorPopup.isOpen =true;
      // errorPopup.statement ="Initial Interval missing";
      setHandlePopup({
        isOpen: true,
        type: 'error',
        Content: 'The maximum end range should be equal to or greater than 9999'
      })
      return canContinue
    }
    for (let i = 0; i < sortedData.length; i++) {
      if (i === (sortedData.length - 1)) {
        // setCount(true)
        canContinue = true
        return canContinue
      }

      if (

        Number(sortedData[i].endRange) < Number(sortedData[i + 1].startRange)
      ) {

        if ((Number(sortedData[i].endRange) + 1) !== Number(sortedData[i + 1].startRange)) {
          setLoader({ ...loader, dropDownLoader: false, mainPage: true });

          setHandlePopup({
            isOpen: true,
            type: 'error',
            Content: `Interval missing between ${Number(sortedData[i].endRange) + 1
              } and ${sortedData[i + 1].startRange - 1}`
          })
          return canContinue
        }
      }
      else {
        setLoader({ ...loader, dropDownLoader: false, mainPage: false });

        setHandlePopup({
          isOpen: true,
          type: 'error',
          Content: "Please correct the range conflicts before submitting"
        })
        return canContinue
      }
    }
  }

  const manageSecondaryCriteria = async () => {
    try {
      const criteriaReqObject = JSON.parse(JSON.stringify(criteriaDetailsForm));

      setLoader({ ...loader, dropDownLoader: false, mainPage: true });
      let req = {
        schedule: selectQueueType,
        status: "WAITING",
        submittedBy: sessionStorage.getItem('userName'),
        type: "SECONDARY",
        companyCode: userInfo.CompanyCode?.length > 0 ? userInfo.CompanyCode[0] : undefined,
        queueData: {
          criteriaDetails: criteriaReqObject
        }
      }
      let arr = Object.keys(criteriaReqObject);
      arr.forEach((item: any) => {
        if (criteriaReqObject[item] == "")
          criteriaReqObject[item] = null;
      });
      if (criteriaReqObject.ruleType == 'EQUATION' && criteriaReqObject.equation.parameters && criteriaReqObject.equation.parameters.length > 0) {
        criteriaReqObject.equation.parameters.forEach((item: any) => {
          delete item.parameterName
        })
      }
      if (criteriaReqObject.ruleType == 'TABLE') {
        const canContinue = await validateTableCriteria()
        if (canContinue == false) {
          setLoader({ ...loader, mainPage: false });
          return
        }
      }

      const response = await addPricingQueueAPI(req);

      if (response.status == 200) {
        setZoneSelectedForm([]);
        setZoneSelected([]);
        setSearchData({
          ...searchData,
          department: '',
          subdepartment: '',
          bracket: '',
          zone: '',
          ruleType: '',
          secondaryCriteriaType: '',
          priceParameter: '',
          brand: '',
          rms: ''
        })
        setPricingQueueId(response.data.pricingQueueId)
        setCriteriaDetailsForm(criteriaDetailsObject)
        setBackupRecords({});
        setSelectQueueType('');
        setHandlePopup({ ...handlePopup, isOpen: true, Content: 'Request has been submitted.', type: 'success' })
        setShowComp({ ...showComp, form: false })
      }
      else if (response.status == 400) {
        const content = response.data?.errors[0]?.error?.trim()?.length > 0 ? response.data?.errors[0]?.error?.trim() : 'Something went wrong';
        setHandlePopup({ isOpen: true, Content: content, index: 0, type: 'error' });
      } else {
        const content = response.data?.errors[0]?.error?.trim()?.length > 0 ? response.data?.errors[0]?.error?.trim() : 'Something went wrong'
        setHandlePopup({ isOpen: true, Content: content, index: 0, type: 'error' });
      }
      setLoader({ ...loader, mainPage: false });

    }
    catch (e) {
      console.error('Error', e);
      setLoader({ ...loader, mainPage: false });
      setHandlePopup({ isOpen: true, Content: 'Something went wrong', index: 0, type: 'error' });
    }
  }


  function FormButtons() {
    return (
      <>
        <RACButton
          variant="contained"
          color="primary"
          size="small"
          style={{ float: "right" }}
          disabled={disableSubmitBtn() ? false : true}
          onClick={() => {             
            if ((userInfo.accessPrevilages.equationAccess == false) && criteriaDetailsForm.ruleType == 'EQUATION') { 
              setHandlePopup({ isOpen: true, Content: equationAccessError, index: 0, type: 'error' });
              } else {manageSecondaryCriteria()   } }}
        >
          Submit
        </RACButton>
        <RACButton
          variant="outlined"
          color="primary"
          size="small"
          style={{ float: "right", marginRight: "1%" }}
          onClick={() => {
            handleCancelClick();
          }}
        >
          Cancel
        </RACButton>
      </>
    )
  }
  const approvedFn = () => {
    return (
      <>
        <Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <SuccessIcon width={"10%"} height={"10%"} />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                padding: "5%",
              }}
            >
              Request has been approved.
            </Typography>
          </Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <RACButton
              onClick={() => {
                setIsApproved(false);
                setIsSLACompleted(false);
                setIsSLACancel(false);
                // updatePricingQueueStatus('A')
              }}
              color="primary"
              variant="contained"
            >
              OK
            </RACButton>
          </Grid>

        </Grid>
      </>
    );
  }
  return (
    <>
      {loader.mainPage ? (
        <Grid className={classes.masterLoader}>
          <Grid className={classes.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      <Grid className={`${classes.pagePadding}`}>
        {showComp.form ?
          <SecondaryCriteriaForm />
          :
          <>
            <SecondaryCriteriaFilter />
            {showComp.grid ? <SecondaryCriteriaGrid /> : null}
          </>
        }
        <Card
          style={{
            position: "fixed",
            bottom: "0px",
            width: "100%",
            zIndex: "1",
            left: "0",
          }}
        >
          <CardContent style={{ padding: '5px 10px 10px 0px' }}>
            <Grid
              md={12}
              lg={12}
              sm={12}
              xs={12}
              spacing={3}
            >
              {showComp.form == false ? <RACButton
                variant="contained"
                color="primary"
                size="small"
                style={{ float: "right" }}
                onClick={() => setShowComp({ ...showComp, form: true, })}
              >
                New Secondary Criteria
              </RACButton>
                : FormButtons()
              }

            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <RACModalCard
        isOpen={handlePopup.isOpen}
        maxWidth="xs"
        borderRadius={10}
        closeIcon={true}
        onClose={() => setHandlePopup({ ...handlePopup, isOpen: false })}
      >
        {poupUp()}
      </RACModalCard>
      <RACModalCard
        isOpen={isApproved}
        maxWidth="xs"
        borderRadius={10}
        closeIcon={true}
        onClose={() => {
          setIsSLACompleted(false);
          setIsSLACancel(false);
          setIsApproved(false)
        }}
      >
        {approvedFn()}
      </RACModalCard>
      <TwoFactorAuthentication
        IsHigherAuthority={userInfo.isApprover} isVisible={authOpen} setIsVisible={setAuthOpen} loader={loader.mainPage} setloader={(e: Boolean) => {
          setLoader({ ...loader, mainPage: e })
        }} setTwoFactorCompleted={setIsSLACompleted} setTwoFactorCancelClick={setIsSLACancel} twoFactorCompleted={isSLACompleted} twoFactorCancelClick={isSLACancel} setSomethingWentWrong={(e: any) => {
          setHandlePopup({ isOpen: e, Content: 'Something went Wrong', index: 0, type: 'error' });
        }} mailId={userInfo.emailId} employeeId={userInfo.employeeId} submitedBy={sessionStorage.getItem('userName')} ApprovedBy={setApprovedBy} />
    </>
  )
}
